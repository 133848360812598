<template>
  <div class="agenda">
    <div class="agendItem">
      <el-dialog
          :title="'预约面试'"
          :visible="orderVisible"
          width="700px"
          class="videoPlayDialog"
          @close="orderVisibleChange()"
      >
        <el-form label-width="90px" v-loading="loading">
          <el-form-item prop="date" class="time">
            <template slot="label">
              <div class="labelSlot">预约日期</div>
            </template>
            <el-select
                style="width: 300px"
                size="small"
                v-model="agendaForm.day"
                placeholder="预约日期"
                @change="selectday"
            >
              <el-option
                  v-for="(item, index) in formatAllAgenda"
                  :key="index"
                  :value="item.date"
                  :label="item.date"
                  :disabled="item.now_time > item.timer"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="end_time" class="time">
            <template slot="label">
              <div class="labelSlot">预约时间</div>
            </template>
            <el-select
                style="width: 300px"
                size="small"
                v-model="agendaForm.agenda_id"
                placeholder="选择预约时间"
                @change="change"
            >
              <el-option
                  v-for="item in timeoptions"
                  :key="item.agenda_id"
                  :value="item.agenda_id"
                  :label="item.timedate"
                  :disabled="item.end_time < item.now_time"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="send_postscript" label="备注">
            <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="agendaForm.send_postscript"
                maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div></div>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="orderVisibleChange()" size="small">取消</el-button>
          <el-button
              type="primary"
              size="small"
              @click="confimAgenda"
              :loading="btnLoading"
          >确认</el-button
          >
        </div>
      </el-dialog>
    </div>

    <el-dialog
        :title="LOCALE == 'en' ? 'Hint' : '提示'"
        :visible.sync="dialogVisible"
        width="400px"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
    >
      <div>{{ dialogTxt }}</div>
      <!--{{$t('i18n.notopen')}}  -->
    </el-dialog>
  </div>
</template>
<script>
import { secondFormat, formatDate } from "~/basePlugins/filters";
export default {
  props: {
    orderVisible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    orderVisible() {
      if (this.orderVisible) {
        this.getAllAgenda();
      }
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      btnLoading: false,
      dialogVisible: false,
      dialogTxt: "",
      chargeList: [],
      agendaForm: {
        // day: "",
        // agenda_id: "",
        // send_postscript: "",
      },
      pitchObj: {},
      allAgenda: [],
      formatAllAgenda: [],
      loading: false,
      //议程所有的日期列表
      dateList: [],
      timeoptions: [],
      joinpage: 1,
    };
  },
  methods: {
    async getAllAgenda() {
      this.loading = true;
      let params = {};
      let now_time = moment().startOf("day");
      params.meeting_id = this.MEETING_ID;
      try {
        let res = await this.$store.dispatch(
            "API_position/getMeetAgendaList",
            params
        );
        if (res.success) {
          this.loading = false;
          let data = res.data.filter((item) => item.agenda_type == 3);
          this.allAgenda = data;
          this.formatAllAgenda = formatDate(this.allAgenda);
          this.formatAllAgenda.forEach((ee, vv) => {
            ee.id = vv;
            ee.now_time = moment(now_time).unix();
            ee.timer = moment(ee.date).unix();
            ee.dataList.forEach((eee, vvv) => {
              eee.now_time = moment(new Date()).unix();
              eee.timedate = `${secondFormat(
                  eee.start_time,
                  "T"
              )}-${secondFormat(eee.end_time, "T")}`;
            });
          });
        }
      } catch (e) {
        this.loading = false;
      }
    },
    show(val) {
      if (this.CHECK_LOGIN) {
        this.agendaForm = {};
      }
    },
    orderVisibleChange(val) {
      this.$emit("orderVisibleShow", val);
    },
    // 获取报名信息
    // async checkUser() {
    //   let enrollInfo = await this.$store.dispatch("checkIsEnroll", {
    //     user_id: this.USER_INFO.id,
    //     company_id: this.USER_INFO.company_id,
    //     meeting_id: this.MEETING_ID,
    //   });
    // },
    confimAgenda() {
      if (!this.agendaForm.day || !this.agendaForm.agenda_id) {
        return false;
      }
      this.btnLoading = true;
      this.$emit("dataChange", this.agendaForm);
    },
    selectday(val) {
      this.formatAllAgenda.forEach((ee, vv) => {
        if (ee.date == val) {
          this.timeoptions = ee.dataList;
        }
      });
      this.agendaForm.agenda_id = "";
    },
    change(e) {
      this.agendaForm.agenda_id = e;
      this.$forceUpdate();
    },
    loadingChange() {
      this.btnLoading = false;
    },
  },
};
</script>
<style scoped lang="less">
.time {
  .el-date-editor {
    width: 50%;
  }

  /deep/ .el-form-item__content {
    display: flex;
  }
}

.labelSlot::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.agendaItem {
}

.mt {
  margin-top: 15px;
}

.bt {
  margin-bottom: 10px;
}

.notopen {
  text-indent: 2em !important;
}
/deep/ .el-dialog__body {
  padding: 20px 30px 0 20px;
}

</style>
